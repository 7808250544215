var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var RH,TH,VH,dI,gI,lI;$CLJS.QH=new $CLJS.M(null,"get-month","get-month",-369374731);RH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.SH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);TH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.UH=new $CLJS.M(null,"iso","iso",-1366207543);VH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.WH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.XH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.YH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.ZH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.$H=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.aI=new $CLJS.M(null,"us","us",746429226);$CLJS.bI=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.cI=new $CLJS.M(null,"now","now",-1650525531);
dI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.eI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.fI=new $CLJS.M(null,"get-day","get-day",127568857);gI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.hI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.iI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.jI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.kI=new $CLJS.M(null,"get-hour","get-hour",622714059);lI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.mI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.nI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.oI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.pI=new $CLJS.M(null,"target","target",253001721);$CLJS.xE($CLJS.ZA,$CLJS.H([$CLJS.$r,$CLJS.bC,$CLJS.qr,$CLJS.bG]));$CLJS.LD.m(null,$CLJS.ZH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.MD(a);return $CLJS.rd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.vk,null,$CLJS.Bk,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.qE($CLJS.ZH,$CLJS.cE);
for(var qI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,$CLJS.eB],null)),rI=null,sI=0,tI=0;;)if(tI<sI){var uI=rI.X(null,tI);$CLJS.xE(uI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null),$CLJS.qr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bG],null)]));$CLJS.qE(uI,$CLJS.ZH);tI+=1}else{var vI=$CLJS.y(qI);if(vI){var wI=vI;if($CLJS.wd(wI)){var xI=$CLJS.ic(wI),xia=$CLJS.jc(wI),yia=xI,zia=$CLJS.D(xI);qI=xia;rI=yia;sI=zia}else{var yI=$CLJS.z(wI);$CLJS.xE(yI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.VD],null),$CLJS.qr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bG],null)]));$CLJS.qE(yI,$CLJS.ZH);qI=$CLJS.B(wI);rI=null;sI=0}tI=0}else break}
for(var zI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XH,$CLJS.QH,$CLJS.fI,$CLJS.kI,$CLJS.hI,$CLJS.WH,$CLJS.mI],null)),AI=null,BI=0,CI=0;;)if(CI<BI){var Aia=AI.X(null,CI);$CLJS.xE(Aia,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));CI+=1}else{var DI=$CLJS.y(zI);if(DI){var EI=DI;if($CLJS.wd(EI)){var FI=$CLJS.ic(EI),Bia=$CLJS.jc(EI),Cia=FI,Dia=$CLJS.D(FI);zI=Bia;AI=Cia;BI=Dia}else{var Eia=$CLJS.z(EI);$CLJS.xE(Eia,$CLJS.H([$CLJS.$r,
$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));zI=$CLJS.B(EI);AI=null;BI=0}CI=0}else break}$CLJS.xE($CLJS.YA,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZF],null)]));
for(var GI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.mI,null,$CLJS.hI,null,$CLJS.kI,null,$CLJS.iI,null,$CLJS.WH,null,$CLJS.QH,null,$CLJS.XH,null,$CLJS.fI,null],null),null)),HI=null,II=0,JI=0;;)if(JI<II){var Fia=HI.X(null,JI);$CLJS.xE(Fia,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));JI+=1}else{var KI=$CLJS.y(GI);if(KI){var LI=KI;if($CLJS.wd(LI)){var MI=$CLJS.ic(LI),Gia=$CLJS.jc(LI),Hia=MI,Iia=$CLJS.D(MI);
GI=Gia;HI=Hia;II=Iia}else{var Jia=$CLJS.z(LI);$CLJS.xE(Jia,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));GI=$CLJS.B(LI);HI=null;II=0}JI=0}else break}$CLJS.Y(lI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.UH,$CLJS.aI,$CLJS.eI],null));
$CLJS.zE($CLJS.aB,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.jI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.qD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,2,[$CLJS.us,"valid timezone ID",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.aj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Ed,$CLJS.PH.tz.names())),$CLJS.GH],null)],null));
$CLJS.zE($CLJS.SH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jI],null)],null)],null)],
null)]));$CLJS.qE($CLJS.SH,$CLJS.ZH);$CLJS.xE($CLJS.cI,$CLJS.H([$CLJS.$r,$CLJS.yk]));$CLJS.Y(VH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vk,$CLJS.Bk],null))}],null)],null));
$CLJS.Y(RH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VH],null)],null)],null)],null));
$CLJS.KF.g($CLJS.YH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.YH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.KH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.aG],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WF],null)],null)],null)],null)],null)],null));
$CLJS.LD.m(null,$CLJS.YH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Bk:$CLJS.n($CLJS.pE($CLJS.aG,a))?$CLJS.vk:$CLJS.Bk:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.AH,b))?$CLJS.vk:$CLJS.n($CLJS.dh($CLJS.BH,b))?$CLJS.vk:null:null;if($CLJS.n(a))return a;b=$CLJS.MD(b);b=$CLJS.rd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.oj)}),b):b;return $CLJS.rd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(gI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),$CLJS.qr],null));
$CLJS.zE($CLJS.$A,$CLJS.H([$CLJS.$r,$CLJS.Bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bG],null)],null)],null)],null)]));
$CLJS.xE($CLJS.Fx,$CLJS.H([$CLJS.$r,$CLJS.Lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$F],null)]));$CLJS.Y(TH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.br,$CLJS.YF,$CLJS.wk,$CLJS.Hk,$CLJS.$H,$CLJS.nI,$CLJS.oI,$CLJS.Ck,$CLJS.xk,$CLJS.uk,$CLJS.Ak,$CLJS.VF],null));$CLJS.Y(dI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.UH,$CLJS.aI,$CLJS.eI],null));
$CLJS.zE($CLJS.cB,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dI],null)],null)],
null)],null)]));