var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uG,vG,xG,yG,xha,yha,zha,zG,wG;$CLJS.sG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.K.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.tG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
uG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.vk)?$CLJS.dG:$CLJS.Dz(b,$CLJS.Lk)?$CLJS.$F:$CLJS.Dz(b,$CLJS.Bk)?$CLJS.bG:null;return $CLJS.n(b)?$CLJS.pE(b,a):!0};
vG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.sG(function(d){return $CLJS.Dz($CLJS.MD(d),$CLJS.bC)},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,!1);b=$CLJS.K.g(b,!0);if($CLJS.Wj.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.MD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(uG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
xG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Fs,function(b){b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.aj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(vG(b))].join("")}],null),$CLJS.Oe(vG)],null)],null)};
yG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.sE,$CLJS.cf.g(function(b){var c=$CLJS.MD(b),d=$CLJS.Dz(c,$CLJS.ZD);b=d?$CLJS.pE($CLJS.oF,b):d;return $CLJS.n(b)?$CLJS.jj:c},a))};yha=function(a){a=$CLJS.tG(function(b){return!$CLJS.Dz(b,$CLJS.bC)},$CLJS.cf.g($CLJS.MD,a));return $CLJS.Dz(a,$CLJS.ZD)?$CLJS.oj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.MD(c),$CLJS.bC)},b))?yha(b):$CLJS.E.g(a,$CLJS.$r)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.MD(c),$CLJS.vk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.MD(c),$CLJS.Bk)},b))?$CLJS.bC:xha(b)};zG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.AG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);wG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,wG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.us,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.MD(a);return $CLJS.Le(function(d){return uG(d,c)},b)}],null)],null));
$CLJS.Y(zG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.$r],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],
null);$CLJS.KF.g($CLJS.Jq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,xG($CLJS.Jq),yG($CLJS.Jq)],null));$CLJS.KF.g($CLJS.$r,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,xG($CLJS.$r),Aha,yG($CLJS.$r)],null));$CLJS.zE($CLJS.Kq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,zG],null)]));$CLJS.zE($CLJS.FF,$CLJS.H([$CLJS.$r,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,zG],null)]));
for(var BG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jq,$CLJS.$r,$CLJS.Kq],null)),CG=null,DG=0,EG=0;;)if(EG<DG){var Bha=CG.X(null,EG);$CLJS.qE(Bha,$CLJS.AG);EG+=1}else{var FG=$CLJS.y(BG);if(FG){var GG=FG;if($CLJS.wd(GG)){var HG=$CLJS.ic(GG),Cha=$CLJS.jc(GG),Dha=HG,Eha=$CLJS.D(HG);BG=Cha;CG=Dha;DG=Eha}else{var Fha=$CLJS.z(GG);$CLJS.qE(Fha,$CLJS.AG);BG=$CLJS.B(GG);CG=null;DG=0}EG=0}else break}
$CLJS.LD.m(null,$CLJS.AG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.xE($CLJS.KE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));$CLJS.qE($CLJS.KE,$CLJS.cE);
for(var IG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.BE,$CLJS.YE],null)),JG=null,KG=0,LG=0;;)if(LG<KG){var Gha=JG.X(null,LG);$CLJS.xE(Gha,$CLJS.H([$CLJS.$r,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));LG+=1}else{var MG=$CLJS.y(IG);if(MG){var NG=MG;if($CLJS.wd(NG)){var OG=$CLJS.ic(NG),Hha=$CLJS.jc(NG),Iha=OG,Jha=$CLJS.D(OG);IG=Hha;JG=Iha;KG=Jha}else{var Kha=$CLJS.z(NG);$CLJS.xE(Kha,$CLJS.H([$CLJS.$r,$CLJS.MC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));IG=$CLJS.B(NG);JG=null;KG=0}LG=0}else break}
for(var PG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,$CLJS.iF,$CLJS.pF],null)),QG=null,RG=0,SG=0;;)if(SG<RG){var Lha=QG.X(null,SG);$CLJS.xE(Lha,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));SG+=1}else{var TG=$CLJS.y(PG);if(TG){var UG=TG;if($CLJS.wd(UG)){var VG=$CLJS.ic(UG),Mha=$CLJS.jc(UG),Nha=VG,Oha=$CLJS.D(VG);PG=Mha;QG=Nha;RG=Oha}else{var Pha=$CLJS.z(UG);$CLJS.xE(Pha,$CLJS.H([$CLJS.$r,$CLJS.Vi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));PG=$CLJS.B(UG);QG=null;RG=0}SG=0}else break}$CLJS.xE($CLJS.HE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null)]));
$CLJS.LD.m(null,$CLJS.HE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.MD(b),$CLJS.Vi)&&$CLJS.Dz($CLJS.MD(a),$CLJS.Vi)?$CLJS.Vi:$CLJS.MC});