var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.oF)};$CLJS.AK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.dD],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.kF],null),LK,$CLJS.qD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.kF],null),DK,$CLJS.hK],null));
$CLJS.KF.g($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.kF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.hK,$CLJS.qD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.ui,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.qE($CLJS.kF,$CLJS.oF);$CLJS.LD.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.xE($CLJS.qA,$CLJS.H([$CLJS.qD]));$CLJS.LD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});
$CLJS.qE($CLJS.qA,$CLJS.oF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.wD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.qD],null)],null)],null));
$CLJS.KF.g($CLJS.$E,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.$E],null),BK,$CLJS.qj],null));$CLJS.LD.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.mi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.qE($CLJS.$E,$CLJS.oF);$CLJS.xE($CLJS.IE,$CLJS.H([$CLJS.$r,$CLJS.hj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OJ],null)],null)]));$CLJS.qE($CLJS.IE,$CLJS.oF);
$CLJS.xE($CLJS.fF,$CLJS.H([$CLJS.$r,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MJ],null)],null)]));$CLJS.qE($CLJS.fF,$CLJS.oF);
$CLJS.Y($CLJS.oF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Ed,$CLJS.sk.g($CLJS.q($CLJS.gB),$CLJS.oF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));