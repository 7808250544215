var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var G7,I7,K7,zta,M7,N7,Ata;G7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Hl(a));};$CLJS.H7=function(a){return $CLJS.Ua(a)?$CLJS.Ly(a,$CLJS.H([$CLJS.ii,!0])):a};
I7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=I7[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=I7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.setting",a);}return a};$CLJS.J7=function(a,b){return I7($CLJS.b_(a),b)};K7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Uh("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.xi,"ceil"],null));a=$CLJS.Rd(a);return 1<a?a:1};
zta=function(a,b,c){var d=G7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.yz(d))/Math.log(10)));return $CLJS.Me(function(f){f*=e;return f>=d?f:null},$CLJS.esa)};
$CLJS.L7=function(a,b,c){var d=$CLJS.g5(b);if($CLJS.n(d)){var e=$CLJS.CD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QM,$CLJS.Oi,$CLJS.jj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.K.g(a,$CLJS.pi);var f=$CLJS.K.g(a,$CLJS.wj);a=$CLJS.O(d);a=$CLJS.K.g(a,$CLJS.jD);a=zta(e,f,a);return new $CLJS.h(null,3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.K.g(a,$CLJS.BD),new $CLJS.h(null,
3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null);case "default":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QM,$CLJS.Oi,$CLJS.jj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.K.g(d,$CLJS.pi);f=$CLJS.K.g(d,$CLJS.wj);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,null,$CLJS.BD,G7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,
2,[$CLJS.BD,null,$CLJS.jD,K7(d,e,null)],null)],null);break a;case "default":$CLJS.SZ(b)?(a=$CLJS.J7(a,$CLJS.dsa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,2,[$CLJS.BD,a,$CLJS.jD,K7(d,e,a)],null)],null)):(a=$CLJS.J7(a,$CLJS.csa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,a,$CLJS.BD,G7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.K.g(a,$CLJS.BD),new $CLJS.h(null,3,[$CLJS.BD,a,$CLJS.GK,c,$CLJS.JK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};M7=function(a){var b=$CLJS.dz.h($CLJS.Y0(a));b=b.h?b.h($CLJS.E7):b.call(null,$CLJS.E7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.pd($CLJS.t4.g(b,-1))&&$CLJS.pd($CLJS.Q5.g(b,-1))){if(b=$CLJS.ak.j(b,$CLJS.rK,$CLJS.od),!$CLJS.y($CLJS.rK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
N7=function(a,b){var c=M7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.wU(b,new $CLJS.h(null,2,[$CLJS.GV,$CLJS.F7,$CLJS.LV,$CLJS.yta],null));var d=$CLJS.U_,e=d.j,f=a;var k=a;var l=$CLJS.I1(k,-1);k=$CLJS.n(l)?$CLJS.CV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.U1.v(a,-2,$CLJS.nV(b),d);if($CLJS.n(b))a=$CLJS.ak.j(a,$CLJS.rK,$CLJS.od);else return null}};
Ata=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.g5(b))?function(){var f=$CLJS.O5(b,null);if(null!=c){var k=$CLJS.L7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.K.g(l,$CLJS.GK);l=$CLJS.K.g(l,$CLJS.JK);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.N5(f,k),$CLJS.M5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.G1($CLJS.LE,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.F7.h(b);return $CLJS.n(f)?$CLJS.g3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.Z2)(e,c)],null)}();return $CLJS.cb(function(e,f){return $CLJS.$2.j(e,-1,f)},a,d)};
$CLJS.O7=function(a,b){b=$CLJS.O(b);var c=$CLJS.K.g(b,$CLJS.d6),d=$CLJS.K.g(b,$CLJS.HK);b=$CLJS.NV.l(a,-1,$CLJS.Qj,$CLJS.H([$CLJS.$E,$CLJS.ZE,$CLJS.LQ,$CLJS.hR,$CLJS.SD]));b=$CLJS.cb(function(f,k){var l=$CLJS.O(k);k=$CLJS.K.g(l,$CLJS.yi);l=$CLJS.K.g(l,$CLJS.aj);return Ata(f,k,l)},b,function(){return function l(k){return new $CLJS.ie(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.wd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.id(t,x);$CLJS.Wj.g($CLJS.NJ.h($CLJS.yi.h(A)),
$CLJS.kK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);if($CLJS.Wj.g($CLJS.NJ.h($CLJS.yi.h(v)),$CLJS.kK))return $CLJS.ae(v,l($CLJS.Hc(m)));m=$CLJS.Hc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.fd(c);return $CLJS.n(f)?$CLJS.tG(function(k){return $CLJS.E.g($CLJS.p5(k),f)},$CLJS.t4.g(a,-1)):null}();return $CLJS.cb(function(f,k){return $CLJS.$2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fd(e)],null);case "metric":return $CLJS.a3.g($CLJS.R.j($CLJS.VX.h((0,$CLJS.zX)($CLJS.F1($CLJS.H7(new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.GE,$CLJS.GE,$CLJS.H7($CLJS.EY.h($CLJS.Q3(a,$CLJS.fd(e))))],null)),$CLJS.Oi,$CLJS.GE))),$CLJS.SO,$CLJS.SO.h(a)),-1);default:return null}}():null)};$CLJS.Y5.m(null,$CLJS.W6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.K.g(b,$CLJS.Q6);b=$CLJS.K.g(b,$CLJS.MY);return new $CLJS.h(null,3,[$CLJS.Oi,$CLJS.W6,$CLJS.Q6,a,$CLJS.MY,b],null)});
$CLJS.X5.m(null,$CLJS.W6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.O7(M7(c),$CLJS.ak.j(e,$CLJS.HK,function(f){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.wd(u)){var v=$CLJS.ic(u),x=$CLJS.D(v),A=$CLJS.le(x);return function(){for(var G=0;;)if(G<x){var J=$CLJS.id(v,
G);$CLJS.pe(A,$CLJS.ak.j(J,$CLJS.yi,function(){return function(S){return N7(c,S)}}(G,J,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.oe($CLJS.qe(A),m($CLJS.jc(u))):$CLJS.oe($CLJS.qe(A),null)}var C=$CLJS.z(u);return $CLJS.ae($CLJS.ak.j(C,$CLJS.yi,function(){return function(G){return N7(c,G)}}(C,u,t)),m($CLJS.Hc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=b;return a}());